import React, { useContext, useMemo } from 'react'
import { Formik, Form } from 'formik'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment/moment'
import { PencilIcon } from '@heroicons/react/solid'

import {
    setSurgicalTeamErrors,
    transformSurgeryFormToApi,
    useGetExpenses,
    useGetOperation,
    useGetPatient,
    useGetProcedure,
    useUpdateOperation,
} from 'api'
import alertContext from 'contexts/alerts/alertContext'
import {
    EXPENSES_TYPE_HOSPITAL_DAY_COST,
    OPERATION_STATUS_DRAFT,
    OPERATION_STATUS_NEW,
    MESSAGES,
    ANESTHESIA_TYPES,
    STATUSES_ALLOWED_TO_EDIT_OPERATION,
} from 'constants/index'
import { AccessDenied } from 'components'
import {
    useNotification,
    useOperationAccountancyPolicy,
    useSurgeryPolicy,
} from 'hooks'
import { isPatientName, displayPatientName, validatePhoneNumber } from 'helpers'
import ListLayout from 'layouts/ListLayout'
import { Button, Loader } from 'components/ui'
import CaregiverSurgeryForm from 'components/CaregiverSurgeryForm'

import type { FormSubmitFn, SurgeryForm } from 'types'
import type { OperationResponse } from 'api/types'

const CaregiverSurgeryFormContainer: React.FC<{ isPreview?: boolean }> = ({
    isPreview,
}) => {
    const { id } = useParams()
    const location = useLocation()
    const showNotification = useNotification()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { add: alert } = useContext(alertContext)

    const { isAllowed: isOperationAccountancy } =
        useOperationAccountancyPolicy()

    const { canUpdate } = useSurgeryPolicy()

    const operation = useGetOperation(id, { enabled: canUpdate })
    const hasPatient = !!operation.data?.data.patient
    const hasProcedure = !!operation.data?.data.procedure
    const patient = useGetPatient(operation.data?.data.patient?.id, {
        enabled: canUpdate && operation.isSuccess && hasPatient,
    })
    const procedure = useGetProcedure(operation.data?.data.procedure?.id, {
        enabled: canUpdate && operation.isSuccess && hasProcedure,
    })
    const expenses = useGetExpenses(
        { type: EXPENSES_TYPE_HOSPITAL_DAY_COST },
        { enabled: canUpdate }
    )
    const { mutate: update } = useUpdateOperation()

    const backUrl = `/caregiver/surgeries/${id}/preview`

    const isPerformed = useMemo(
        () =>
            !STATUSES_ALLOWED_TO_EDIT_OPERATION.includes(
                operation.data?.data.status || ''
            ),
        [operation.data?.data.status]
    )

    const handleSubmit: FormSubmitFn<SurgeryForm> = (values, formikHelpers) => {
        if (!id) {
            return
        }

        if (
            values.patient?.phone &&
            !isPerformed &&
            !validatePhoneNumber(values.patient.phone)
        ) {
            formikHelpers.setSubmitting(false)
            return alert({
                content:
                    'Niepoprawny numer telefonu pacjenta. Dopuszczalne tylko cyfry i znak +, maks. długość numeru 15 znaków',
                type: 'danger',
            })
        }

        const isDraft = operation.data?.data.status === OPERATION_STATUS_DRAFT

        const data = Object.assign(
            transformSurgeryFormToApi(
                Object.assign(
                    {},
                    values,
                    { prevalidate: false },
                    isDraft
                        ? values._isDraftSaving
                            ? {
                                  status: OPERATION_STATUS_DRAFT,
                              }
                            : {
                                  status: OPERATION_STATUS_NEW,
                              }
                        : {},
                    values.anesthesia_type?.id !== 'with_anesthesiologist'
                        ? {
                              anesthesia_info: '',
                              weight: '',
                          }
                        : {}
                )
            ),
            !isOperationAccountancy || !isPerformed
                ? {
                      costs_resolved: undefined,
                      implants_used: undefined,
                  }
                : {}
        )

        update(
            {
                id,
                data,
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries('operations')
                    await queryClient.invalidateQueries('schedules')

                    formikHelpers.setSubmitting(false)

                    if (isDraft) {
                        if (!values._isDraftSaving) {
                            showNotification({
                                content: MESSAGES.SURGERY_TO_SCHEDULE_ADDED,
                                type: 'success',
                            })

                            navigate('/schedule/edit', {
                                state: {
                                    date: moment(
                                        values.estimated_date
                                    ).toDate(),
                                },
                            })

                            return
                        }
                    }

                    showNotification({
                        content: MESSAGES.SURGERY_UPDATED,
                        type: 'success',
                    })

                    navigate(backUrl, {
                        state: location.state?.state,
                    })
                },
                onError: (error) => {
                    showNotification({
                        content: error.message,
                        type: 'danger',
                    })
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)

                    setSurgicalTeamErrors(
                        data.surgical_team,
                        error.errors,
                        formikHelpers.setFieldError
                    )
                },
            }
        )
    }

    if (!canUpdate) {
        return (
            <AccessDenied message={MESSAGES.NO_PERMISSION_TO_UPDATE_SURGERY} />
        )
    }

    return (
        <>
            {[
                operation.isLoading,
                patient.isLoading,
                procedure.isLoading,
                expenses.isLoading,
            ].some((isLoading) => isLoading) && <Loader />}
            {operation.isError && <div>{operation.error.message}</div>}
            {patient.isError && <div>{patient.error.message}</div>}
            {procedure.isError && <div>{procedure.error.message}</div>}
            {expenses.isError && <div>{expenses.error.message}</div>}
            {operation.isSuccess &&
                (hasPatient ? patient.isSuccess : true) &&
                (hasProcedure ? procedure.isSuccess : true) && (
                    <ListLayout
                        title={
                            isPreview
                                ? 'Formularz operacji'
                                : 'Edycja danych operacji'
                        }
                        actions={
                            <div>
                                {isPreview && (
                                    <Button
                                        size="sm"
                                        variant="primary"
                                        iconRight={
                                            <PencilIcon className="w-5 h-5" />
                                        }
                                        onClick={() =>
                                            navigate(
                                                `/caregiver/surgeries/${id}/edit`
                                            )
                                        }
                                    >
                                        Edytuj operację
                                    </Button>
                                )}
                            </div>
                        }
                    >
                        <Formik
                            initialValues={
                                {
                                    _isDraftSaving: false,
                                    surgical_team: (
                                        operation.data.data.surgical_team || []
                                    ).map((item) => ({
                                        id: item.id,
                                        role: item.role,
                                    })),
                                    doctor: hasSurgicalOperator(
                                        operation.data.data
                                    )
                                        ? getSurgicalOperator(
                                              operation.data.data
                                          )
                                        : undefined,
                                    assistant: hasSurgicalAssistant(
                                        operation.data.data
                                    )
                                        ? getSurgicalAssistant(
                                              operation.data.data
                                          )
                                        : [null, null],
                                    patient: patient.data?.data,
                                    _patient_item: patient.data?.data,
                                    _patient_item_phrase: isPatientName(
                                        operation.data.data.patient
                                    )
                                        ? displayPatientName(
                                              operation.data.data.patient
                                          )
                                        : '',
                                    anesthesia_type: operation.data.data
                                        .anesthesia_type
                                        ? ANESTHESIA_TYPES.find(
                                              (item) =>
                                                  item.id ===
                                                  operation.data.data
                                                      .anesthesia_type
                                          ) || undefined
                                        : undefined,
                                    anesthesia_info:
                                        operation.data.data.anesthesia_info ||
                                        '',
                                    weight: operation.data.data.weight || '',
                                    equipment:
                                        operation.data.data.equipment || [],
                                    _equipment_item: undefined,
                                    _equipment_item_phrase: '',
                                    estimated_date:
                                        operation.data.data.estimated_date,
                                    procedure: procedure.data?.data,
                                    _procedure_item:
                                        operation.data.data.procedure,
                                    _procedure_item_phrase:
                                        operation.data.data.procedure?.name ||
                                        '',
                                    procedure_type:
                                        procedure.data?.data.procedure_type,
                                    procedure_category:
                                        procedure.data?.data.procedure_category,
                                    preferred_beginning_hour: operation.data
                                        .data.preferred_beginning_hour
                                        ? {
                                              id: operation.data.data
                                                  .preferred_beginning_hour,
                                              name: operation.data.data
                                                  .preferred_beginning_hour,
                                          }
                                        : undefined,
                                    estimated_duration: operation.data.data
                                        .estimated_duration
                                        ? {
                                              id: operation.data.data
                                                  .estimated_duration,
                                              name: operation.data.data
                                                  .estimated_duration,
                                          }
                                        : undefined,
                                    recovery_days:
                                        operation.data.data.recovery_days ||
                                        undefined,
                                    hospital_day_cost:
                                        operation.data.data.hospital_day_cost ||
                                        expenses.data?.data[0]?.price ||
                                        0,
                                    additional_bed:
                                        operation.data.data.additional_bed,
                                    description:
                                        operation.data.data.description || '',
                                    diagnosis:
                                        operation.data.data.diagnosis || '',
                                    payer:
                                        operation.data.data.payer || undefined,
                                    base_cost: operation.data.data.payer
                                        ? operation.data.data.base_cost
                                        : 0,
                                    contains_implants: procedure.data?.data
                                        .implants_required
                                        ? true
                                        : !operation.data.data.implants
                                        ? false
                                        : operation.data.data.implants.length >
                                          0,
                                    implants:
                                        operation.data.data.implants || [],
                                    contains_implants_used:
                                        (operation.data.data.implants_used
                                            ? operation.data.data.implants_used
                                            : operation.data.data.implants || []
                                        ).length > 0,
                                    implants_used:
                                        operation.data.data.implants_used || [],
                                    _implant_item_phrase: '',
                                    _implant_item: undefined,
                                    _implant_set_item_phrase: '',
                                    _implant_set_item: undefined,
                                    costs: operation.data.data.costs || [],
                                    _costs_item: undefined,
                                    _costs_item_phrase: '',
                                    costs_resolved:
                                        operation.data.data.costs_resolved ||
                                        [],
                                    advance: operation.data.data.advance || [],
                                    _expense_price: '',
                                } as SurgeryForm
                            }
                            validateOnChange={false}
                            enableReinitialize
                            onSubmit={handleSubmit}
                        >
                            <Form>
                                <div className="space-y-6 xl:col-span-9">
                                    <div className="rounded-md bg-white">
                                        <CaregiverSurgeryForm
                                            isPreview={isPreview}
                                            isDraft={
                                                operation.data.data.status ===
                                                OPERATION_STATUS_DRAFT
                                            }
                                            data={operation.data}
                                            backUrl={backUrl}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </ListLayout>
                )}
        </>
    )
}

function hasSurgicalOperator(data: OperationResponse) {
    return !!getSurgicalOperator(data)
}

function getSurgicalOperator(data: OperationResponse) {
    return data.surgical_team.find((item) => item.role === 'doctor')
}

function hasSurgicalAssistant(data: OperationResponse) {
    return getSurgicalAssistant(data).length
}

function getSurgicalAssistant(data: OperationResponse) {
    return data.surgical_team.filter((item) => item.role === 'assistant')
}

export default CaregiverSurgeryFormContainer
