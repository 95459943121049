import React from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import moment from 'moment'

import ListLayout from 'layouts/ListLayout'
import {
    TextField,
    TextAreaField,
    ActionBar,
    AvailableDatePickerField,
    DropdownField,
    EquipmentField,
    RadioGroupField,
    RadioGroupDefaultGrid,
    ImplantField,
    AdditionalCostField,
    EmployeePickerField,
} from 'components/forms'
import { Button, Label } from 'components/ui'
import {
    RADIO_GROUP_YES_NO_OPTIONS,
    PREFERRED_BEGINNING_HOUR_ITEMS,
} from 'constants/index'
import { getDurationArray } from 'helpers'

import { ReactComponent as IconPipeArrowRight } from 'assets/icon-pipe-arrow-right.svg'

import type { FormSubmitFn, SurgeryDraftForm } from 'types'

const DoctorSurgeryDraftForm: React.FC<{
    initialValues: SurgeryDraftForm
    handleSubmit: FormSubmitFn<SurgeryDraftForm>
}> = ({ initialValues, handleSubmit }) => {
    return (
        <ListLayout title="Nowa operacja spoza cennika">
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form>
                    <div className="rounded-md bg-white">
                        <div className="divide-gray-200 divide-y">
                            <div className="mb-16 pt-12 px-6">
                                <div className="text-gray-700 mb-16">
                                    <div className="grid grid-cols-2 text-gray-700 divide-gray-200 divide-x">
                                        <div className="col-span-1 pr-8">
                                            <SurgeryDraftFormLeftSide />
                                        </div>
                                        <div className="col-span-1 pl-8">
                                            <SurgeryDraftFormRightSide />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-6 py-12">
                                <ActionBar
                                    rightSide={({ isSubmitting }) => (
                                        <>
                                            <Button as="link" to="/">
                                                Anuluj
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="ml-3"
                                                loading={isSubmitting}
                                                iconRight={
                                                    <IconPipeArrowRight />
                                                }
                                            >
                                                Wyślij zapytanie
                                            </Button>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </ListLayout>
    )
}

const SurgeryDraftFormLeftSide = () => {
    return (
        <div className="divide-gray-200 divide-y">
            <section>
                <div className="text-lg leading-6 font-medium text-gray-900">
                    Informacje o pacjencie
                </div>
                <div className="mt-6">
                    <Label>Czy pacjent jest dorosły?</Label>
                    <RadioGroupField
                        name="draft.adult"
                        options={RADIO_GROUP_YES_NO_OPTIONS}
                        Grid={RadioGroupDefaultGrid}
                    />
                </div>
                <div className="mt-6">
                    <Label>Zabieg u pacjenta z zakażeniem?</Label>
                    <RadioGroupField
                        name="draft.infected"
                        options={RADIO_GROUP_YES_NO_OPTIONS}
                        Grid={RadioGroupDefaultGrid}
                    />
                </div>
                <div className="mt-6">
                    <Label>Czy wymaga OIT po zabiegu?</Label>
                    <RadioGroupField
                        name="draft.intensive_care"
                        options={RADIO_GROUP_YES_NO_OPTIONS}
                        Grid={RadioGroupDefaultGrid}
                    />
                </div>
                <div className="mt-6">
                    <Label>Czy wymaga dodatkowych leków niż standardowe?</Label>
                    <RadioGroupField
                        name="draft.additional_medication"
                        options={RADIO_GROUP_YES_NO_OPTIONS}
                        Grid={RadioGroupDefaultGrid}
                    />
                </div>
            </section>
            <section className="mt-6">
                <div className="pt-5">
                    <Label>
                        Czy pacjent wymaga szczegółowego przygotowania przed
                        zabiegiem?
                    </Label>
                    <TextAreaField name="draft.preparation_details" rows={3} />
                </div>
            </section>
            <section className="mt-6">
                <div className="pt-5">
                    <Label>
                        Inne uwagi dotyczące zabiegu lub hospitalizacji?
                    </Label>
                    <TextAreaField name="draft.other_notes" rows={3} />
                </div>
            </section>
            <section className="mt-8">
                <div className="pt-6">
                    <div className="text-lg leading-6 font-medium text-gray-900">
                        Informacje o zabiegu
                    </div>
                    <div className="mt-6">
                        <div className="grid grid-cols-3 gap-3">
                            <div className="col-span-1">
                                <Label>Data operacji</Label>
                                <AvailableDatePickerField
                                    name="estimated_date"
                                    placeholder="DD.MM.RRRR"
                                    disabledBefore={moment().toDate()}
                                />
                            </div>
                            <div className="col-span-1">
                                <DropdownField
                                    name="preferred_beginning_hour"
                                    label="Preferowana pora"
                                    items={PREFERRED_BEGINNING_HOUR_ITEMS}
                                />
                            </div>
                            <div className="col-span-1">
                                <Label>Szacowany czas</Label>
                                <DropdownField
                                    name="estimated_duration"
                                    items={getDurationArray(12, 15)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-6">
                        <Label>Zabieg z anestezjologiem</Label>
                        <RadioGroupField
                            name="draft.anesthesiologist_needed"
                            options={RADIO_GROUP_YES_NO_OPTIONS}
                            Grid={RadioGroupDefaultGrid}
                        />
                    </div>
                    <div className="mt-6">
                        <Label>Potrzebna asysta do zabiegu</Label>
                        <RadioGroupField
                            name="draft.assistant_needed"
                            options={RADIO_GROUP_YES_NO_OPTIONS}
                            Grid={RadioGroupDefaultGrid}
                        />
                    </div>
                </div>
            </section>
        </div>
    )
}

const SurgeryDraftFormRightSide = () => {
    const { values, setFieldValue } = useFormikContext<SurgeryDraftForm>()

    return (
        <div className="divide-gray-200 divide-y">
            <section>
                <div className="text-lg leading-6 font-medium text-gray-900">
                    Operator
                </div>
                <div className="mt-6">
                    <div className="mt-3">
                        <Label>Operator</Label>
                        <EmployeePickerField
                            name="_doctor"
                            placeholder="Szukaj / wybierz lekarza"
                            functions={['doctor function']}
                            onChange={(user) => {
                                setFieldValue('surgical_team', [
                                    {
                                        id: user.id,
                                        role: 'doctor',
                                    },
                                ])
                            }}
                            handleRemove={() => {
                                setFieldValue('surgical_team', [])
                            }}
                            messageEmptyResult="Nie znaleziono lekarza."
                        />
                    </div>
                </div>
            </section>
            <section className="mt-8">
                <div className="pt-6">
                    <div className="text-lg leading-6 font-medium text-gray-900">
                        Hospitalizacja
                    </div>
                    <div className="mt-6">
                        <Label>
                            Liczba dni po zabiegu
                            <span className="ml-1 text-gray-400 font-normal">
                                (0- wypis w dniu zabiegu)
                            </span>
                        </Label>
                        <TextField name="recovery_days" />
                    </div>
                </div>
            </section>
            <section className="mt-8">
                <div className="pt-6">
                    <div className="text-lg leading-6 font-medium text-gray-900">
                        Sprzęt medyczny
                    </div>
                    <div className="mt-6">
                        <EquipmentField name="equipment" editPrice />
                    </div>
                </div>
            </section>
            <section className="mt-8">
                <div className="pb-8">
                    <div className="mt-6 text-lg leading-6 font-medium text-gray-900">
                        Implanty
                    </div>
                    <div className="mt-6">
                        <RadioGroupField<boolean>
                            name="contains_implants"
                            options={RADIO_GROUP_YES_NO_OPTIONS}
                            handleChange={(value) => {
                                !value &&
                                    setFieldValue('_implant_item', undefined) &&
                                    setFieldValue('_implant_item_phrase', '') &&
                                    setFieldValue(
                                        '_implant_set_item',
                                        undefined
                                    ) &&
                                    setFieldValue(
                                        '_implant_set_item_phrase',
                                        ''
                                    )
                            }}
                            Grid={RadioGroupDefaultGrid}
                        />
                    </div>
                </div>
                <div className="mt-6">
                    <ImplantField
                        name="implants"
                        procedure={null}
                        disabled={!values.contains_implants}
                    />
                </div>
                <div className="mt-8">
                    <div className="text-lg leading-6 font-medium text-gray-900">
                        Koszty dodatkowe
                    </div>
                    <div className="mt-4">
                        <AdditionalCostField name="costs" editPrice />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default DoctorSurgeryDraftForm
