export const RADIO_GROUP_YES_NO_OPTIONS = [
    {
        name: 'Tak',
        value: true,
    },
    {
        name: 'Nie',
        value: false,
    },
]

export const PREFERRED_BEGINNING_HOUR_ITEMS = [
    { id: '7-12', name: '7-12' },
    { id: '12-15', name: '12-15' },
    { id: '15-19', name: '15-19' },
]

export const OPERATION_TYPES = [
    { id: 'small', name: 'Operacja mała' },
    { id: 'large', name: 'Operacja duża' },
    { id: 'longterm', name: 'Operacja długoterminowa' },
    { id: 'local', name: 'Operacja miejscowa' },
]

export const ANESTHESIA_TYPES = [
    { id: 'local', name: 'Znieczulenie miejscowe' },
    { id: 'with_anesthesiologist', name: 'Znieczulenie z anestezjologiem' },
]

export const SECURITY_SETTINGS = {
    min_password_length: {
        id: 'min_password_length',
        label: 'Długość',
        options: [
            { id: '12', name: '12 znaków' },
            { id: '13', name: '13 znaków' },
            { id: '14', name: '14 znaków' },
            { id: '15', name: '15 znaków' },
            { id: '16', name: '16 znaków' },
        ],
    },
    min_uppercase_letters: {
        id: 'min_uppercase_letters',
        label: 'Wielka litera',
        options: [
            { id: '1', name: '1 znak' },
            { id: '2', name: '2 znaki' },
            { id: '3', name: '3 znaki' },
        ],
    },
    min_numbers: {
        id: 'min_numbers',
        label: 'Cyfry',
        options: [
            { id: '1', name: '1 cyfra' },
            { id: '2', name: '2 cyfry' },
            { id: '3', name: '3 cyfry' },
        ],
    },
    min_special_characters: {
        id: 'min_special_characters',
        label: 'Znaki specjalne',
        options: [
            { id: '1', name: '1 znak' },
            { id: '2', name: '2 znaki' },
            { id: '3', name: '3 znaki' },
        ],
    },
    account_lockout_threshold: {
        id: 'account_lockout_threshold',
        label: 'Blokada konta po nieudanych próbach',
        options: [
            { id: '3', name: '3 próby' },
            { id: '5', name: '5 prób' },
        ],
    },
    account_confirmation_method: {
        id: 'account_confirmation_method',
        label: 'Pierwsze hasło',
        options: [{ id: 'email', name: 'Wysyłanie linku aktywacyjnego' }],
    },
    account_confirmation_token_expiry: {
        id: 'account_confirmation_token_expiry',
        label: 'Czas ważności linku',
        options: [
            { id: '15', name: '15 min' },
            { id: '30', name: '30 min' },
            { id: '60', name: '1 godz' },
            { id: '480', name: '8 godz' },
            { id: '1440', name: '24 godz' },
            { id: '4320', name: '3 dn' },
            { id: '10080', name: '7 dn' },
        ],
    },
    account_password_recovery_enabled: {
        id: 'account_password_recovery_enabled',
        label: 'Przypomnienie hasła',
        options: [
            { id: '0', name: 'Wył' },
            { id: '1', name: 'Wł' },
        ],
    },
    account_password_recovery_method: {
        id: 'account_password_recovery_method',
        label: 'Zmiana hasła przez',
        options: [{ id: 'email', name: 'Wysyłanie linku' }],
    },
    password_expiration_enabled: {
        id: 'password_expiration_enabled',
        label: '',
        options: [
            { id: '0', name: 'Wył' },
            { id: '1', name: 'Wł' },
        ],
    },
    password_expires_after_days: {
        id: 'password_expires_after_days',
        label: 'Wymuszenie zmiany hasła co',
        options: [],
    },
    unique_passwords_enabled: {
        id: 'unique_passwords_enabled',
        label: '',
        options: [
            { id: '0', name: 'Wył' },
            { id: '1', name: 'Wł' },
        ],
    },
    unique_passwords_in_row: {
        id: 'unique_passwords_in_row',
        label: 'Powtórzenie hasła możliwe co',
        options: [],
    },
}
