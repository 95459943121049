import React, { useMemo } from 'react'

import { useGetSecuritySettings, useUpdateSecuritySettings } from 'api'
import { useNotification, useSecurityGlobalPolicy } from 'hooks'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import SettingsSecurityLayout from 'layouts/SettingsSecurityLayout'
import { SettingsSecurityGeneral } from 'components/SettingsSecurityGeneral'
import { SECURITY_SETTINGS } from '../constants'

import type { FormSubmitFn, SecurityGeneralFormType } from 'types'
import type { SecuritySetting } from '../api/types'

export const SettingsSecurityGeneralContainer: React.FC = () => {
    const showNotification = useNotification()

    const securityGlobalPolicy = useSecurityGlobalPolicy()
    const securitySettings = useGetSecuritySettings({
        enabled: securityGlobalPolicy.canShow,
    })

    const { mutate: updateSecuritySettings } = useUpdateSecuritySettings()

    const handleSubmit: FormSubmitFn<SecurityGeneralFormType> = (
        values,
        formikHelpers
    ) => {
        const isPasswordExpirationEnabled =
            typeof values.settings.password_expiration_enabled === 'object' &&
            values.settings.password_expiration_enabled.id === '1'
        const isUniquePasswordsEnabled =
            typeof values.settings.unique_passwords_enabled === 'object' &&
            values.settings.unique_passwords_enabled.id === '1'

        updateSecuritySettings(
            {
                settings: Object.entries(values.settings)
                    .map(([key, value]) => ({
                        name: key as SecuritySetting['name'],
                        value:
                            typeof value === 'string'
                                ? value
                                : value.id.toString(),
                    }))
                    .filter((item) =>
                        item.name === 'unique_passwords_in_row'
                            ? isUniquePasswordsEnabled
                            : item.name === 'password_expires_after_days'
                            ? isPasswordExpirationEnabled
                            : true
                    ),
                rules: values.rules,
            },

            {
                onSuccess: async () => {
                    formikHelpers.setSubmitting(false)
                    showNotification({
                        content: 'Zmiany zostały zapisane',
                        type: 'success',
                    })

                    securitySettings.refetch()
                },
                onError: () => {
                    formikHelpers.setSubmitting(false)
                    showNotification({
                        content: 'Nie udało się zapisać zmian',
                        type: 'danger',
                    })
                },
            }
        )
    }

    const initialFormData: SecurityGeneralFormType = useMemo(() => {
        return {
            settings:
                securitySettings.data?.data.settings.reduce((acc, item) => {
                    return {
                        ...acc,
                        [item.name]: [
                            'password_expires_after_days',
                            'unique_passwords_in_row',
                        ].includes(item.name)
                            ? item.value
                            : SECURITY_SETTINGS[item.name].options.find(
                                  (option) => option.id === item.value
                              ),
                    }
                }, {}) || {},
            rules: securitySettings.data?.data.rules || [],
        }
    }, [securitySettings])

    return (
        <SettingsSecurityLayout title="Bezpieczeństwo">
            <>
                {!securityGlobalPolicy.canShow && (
                    <AccessDenied message="Nie masz uprawnień" />
                )}
                {securitySettings.isLoading && <Loader />}
                {securitySettings.isError && (
                    <div>{securitySettings.error.message}</div>
                )}
                {securitySettings.isSuccess && (
                    <SettingsSecurityGeneral
                        handleSubmit={handleSubmit}
                        initialFormData={initialFormData}
                    />
                )}
            </>
        </SettingsSecurityLayout>
    )
}
