import React, { useEffect, useState } from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { PencilIcon, PlusIcon } from '@heroicons/react/solid'

import { Button, Toggle } from 'components/ui'
import { useModal } from 'hooks'
import SecurityRuleModal from '../SecurityRuleModal'

import { SecurityRule } from '../../api/types'

const SecurityRulesField: React.FC<{
    isGeneral?: boolean
}> = ({ isGeneral }) => {
    const { values, setFieldValue } =
        useFormikContext<{ rules: SecurityRule[] }>()

    const [chosenRule, setChosenRule] = useState<{
        index?: number
        rule: SecurityRule
    } | null>(null)
    const ruleModal = useModal()

    useEffect(() => {
        !!chosenRule ? ruleModal.openModal() : ruleModal.closeModal()
    }, [chosenRule, ruleModal])

    return (
        <div>
            <div className="text-lg leading-6 font-medium text-gray-900 mb-6 flex items-center justify-between gap-2 w-full">
                Reguły logowania
                <Button
                    variant="tertiary"
                    size="sm"
                    onClick={() => {
                        setChosenRule({
                            rule: {
                                ip_address: '',
                                mfa: true,
                                label: '',
                                allowed: true,
                            },
                        })
                    }}
                    type="button"
                >
                    Dodaj
                    <PlusIcon className="w-4 h-4 ml-2" />
                </Button>
            </div>

            <div>
                <FieldArray
                    name="rules"
                    render={() => (
                        <table className="w-full">
                            {values.rules.map((item, index) => (
                                <tr
                                    key={index}
                                    className="border-b border-gray-200"
                                >
                                    <td className="truncate py-5 pl-2 pr-3 text-sm leading-5 font-normal text-gray-900 max-w-0">
                                        <div className="truncate">
                                            {item.label}
                                        </div>
                                    </td>
                                    <td className="py-5 px-3 w-1 text-sm leading-5 font-medium text-gray-500 whitespace-nowrap">
                                        {item.ip_address || '-'}
                                    </td>
                                    <td className="py-5 px-3 w-1">
                                        <span className="inline-flex items-center gap-3 text-sm leading-5 font-medium text-gray-900">
                                            <Toggle
                                                checked={item.mfa}
                                                handleChange={(checked) =>
                                                    setFieldValue(
                                                        `rules.${index}.mfa`,
                                                        checked
                                                    )
                                                }
                                            />
                                            MFA
                                        </span>
                                    </td>
                                    <td className="py-5 px-3 w-1">
                                        <span className="inline-flex items-center gap-3 text-sm leading-5 font-medium text-gray-900">
                                            <Toggle
                                                disabled={
                                                    isGeneral &&
                                                    !item.ip_address
                                                }
                                                checked={item.allowed}
                                                handleChange={(checked) =>
                                                    setFieldValue(
                                                        `rules.${index}.allowed`,
                                                        checked
                                                    )
                                                }
                                            />
                                            Logowanie
                                        </span>
                                    </td>
                                    <td className="w-1 pr-2">
                                        <PencilIcon
                                            className="w-5 h-5 text-gray-400 hover:text-blue-500 cursor-pointer"
                                            onClick={() =>
                                                setChosenRule({
                                                    index,
                                                    rule: item,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </table>
                    )}
                />
            </div>

            {chosenRule && (
                <SecurityRuleModal
                    modal={ruleModal}
                    onSubmit={(data) => {
                        if (typeof data.index === 'undefined') {
                            setFieldValue('rules', [data.rule, ...values.rules])
                        } else {
                            setFieldValue(
                                `rules.${data.index}.label`,
                                data.rule.label
                            )
                            setFieldValue(
                                `rules.${data.index}.ip_address`,
                                data.rule.ip_address
                            )
                        }
                        setChosenRule(null)
                    }}
                    onClose={() => setChosenRule(null)}
                    onRemove={(index) => {
                        setFieldValue(
                            'rules',
                            values.rules.filter(
                                (_, ruleIndex) => ruleIndex !== index
                            )
                        )
                        setChosenRule(null)
                    }}
                    rule={chosenRule}
                    existingRules={values.rules}
                />
            )}
        </div>
    )
}

export default SecurityRulesField
