import React from 'react'
import { Formik, Form } from 'formik'
import { PlusIcon } from '@heroicons/react/solid'
import * as yup from 'yup'

import { Button, Toggle, Label } from 'components/ui'
import {
    ActionBar,
    DropdownField,
    DropdownItem,
    TextField,
} from 'components/forms'
import { SECURITY_SETTINGS } from '../constants'
import SecurityRules from './forms/SecurityRulesField'

import type { FormSubmitFn, SecurityGeneralFormType } from 'types'

export const SettingsSecurityGeneral: React.FC<{
    initialFormData: SecurityGeneralFormType
    handleSubmit: FormSubmitFn<SecurityGeneralFormType>
}> = ({ initialFormData, handleSubmit }) => {
    return (
        <Formik
            initialValues={initialFormData}
            enableReinitialize
            onSubmit={(values, formikHelpers) => {
                handleSubmit(values, formikHelpers)
            }}
            validateOnChange={false}
            validationSchema={yup.object({
                settings: yup.object({
                    password_expires_after_days: yup
                        .number()
                        .when('password_expiration_enabled', {
                            is: (password_expiration_enabled?: DropdownItem) =>
                                password_expiration_enabled?.id === '1',
                            then: (schema) =>
                                schema
                                    .moreThan(
                                        0,
                                        'Wymagana liczba większa niż 0'
                                    )
                                    .required('Pole jest wymagane')
                                    .typeError(
                                        'Niepoprawna wartość - wymagane tylko cyfry'
                                    ),
                        }),
                    unique_passwords_in_row: yup
                        .number()
                        .when('unique_passwords_enabled', {
                            is: (unique_passwords_enabled?: DropdownItem) =>
                                unique_passwords_enabled?.id === '1',
                            then: (schema) =>
                                schema
                                    .moreThan(
                                        0,
                                        'Wymagana liczba większa niż 0'
                                    )
                                    .integer(
                                        'Niepoprawna wartość - wymagane tylko cyfry'
                                    )
                                    .required('Pole jest wymagane')
                                    .typeError(
                                        'Niepoprawna wartość - wymagane tylko cyfry'
                                    ),
                        }),
                }),
            })}
        >
            {({ setFieldValue, values, errors }) => (
                <>
                    <Form>
                        <div className="rounded-md bg-white px-6 py-12">
                            <div className="divide-gray-200 divide-y">
                                <div className="grid grid-cols-2 text-gray-700 divide-gray-200 divide-x pb-8">
                                    <div className="col-span-1 pr-8 flex flex-col gap-8">
                                        <section className="flex flex-col gap-6">
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Ustawienia hasła
                                            </div>

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .min_password_length
                                                        .label
                                                }
                                                name="settings.min_password_length"
                                                placeholder="-"
                                                items={
                                                    SECURITY_SETTINGS
                                                        .min_password_length
                                                        .options
                                                }
                                            />

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .min_uppercase_letters
                                                        .label
                                                }
                                                name="settings.min_uppercase_letters"
                                                placeholder="-"
                                                items={
                                                    SECURITY_SETTINGS
                                                        .min_uppercase_letters
                                                        .options
                                                }
                                            />

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .min_numbers.label
                                                }
                                                name="settings.min_numbers"
                                                placeholder="-"
                                                items={
                                                    SECURITY_SETTINGS
                                                        .min_numbers.options
                                                }
                                            />

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .min_special_characters
                                                        .label
                                                }
                                                name="settings.min_special_characters"
                                                placeholder="-"
                                                items={
                                                    SECURITY_SETTINGS
                                                        .min_special_characters
                                                        .options
                                                }
                                            />

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .account_lockout_threshold
                                                        .label
                                                }
                                                name="settings.account_lockout_threshold"
                                                bulkOptionName="-"
                                                items={
                                                    SECURITY_SETTINGS
                                                        .account_lockout_threshold
                                                        .options
                                                }
                                            />
                                        </section>

                                        <section className="flex flex-col gap-6">
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Rejestracja
                                            </div>

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .account_confirmation_method
                                                        .label
                                                }
                                                name="settings.account_confirmation_method"
                                                placeholder="-"
                                                items={
                                                    SECURITY_SETTINGS
                                                        .account_confirmation_method
                                                        .options
                                                }
                                                disabled
                                            />

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .account_confirmation_token_expiry
                                                        .label
                                                }
                                                name="settings.account_confirmation_token_expiry"
                                                placeholder="-"
                                                items={
                                                    SECURITY_SETTINGS
                                                        .account_confirmation_token_expiry
                                                        .options
                                                }
                                            />
                                        </section>

                                        <section className="flex flex-col gap-6">
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Funkcja zapomnianego hasła
                                            </div>

                                            <div className="flex gap-16">
                                                <div className="flex flex-col">
                                                    <span className="text-sm leading-5 font-medium text-gray-700 mb-1">
                                                        Przypomnienie hasła
                                                    </span>

                                                    <span className="inline-flex items-center gap-3 text-sm leading-5 font-medium text-gray-900">
                                                        <Toggle
                                                            checked
                                                            disabled
                                                            handleChange={(
                                                                checked
                                                            ) =>
                                                                setFieldValue(
                                                                    'settings.account_password_recovery_enabled',
                                                                    SECURITY_SETTINGS.account_password_recovery_enabled.options.find(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.id ===
                                                                            (checked
                                                                                ? '1'
                                                                                : '0')
                                                                    )
                                                                )
                                                            }
                                                        />
                                                        Włącz
                                                    </span>
                                                </div>
                                            </div>

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .account_password_recovery_method
                                                        .label
                                                }
                                                name="settings.account_password_recovery_method"
                                                placeholder="-"
                                                items={
                                                    SECURITY_SETTINGS
                                                        .account_password_recovery_method
                                                        .options
                                                }
                                                disabled
                                            />
                                        </section>
                                    </div>

                                    <div className="col-span-1 pl-8 flex flex-col gap-8">
                                        <section>
                                            <SecurityRules isGeneral />
                                        </section>

                                        <section className="flex flex-col gap-6">
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Zmiana hasła
                                            </div>

                                            <div>
                                                <div className="flex items-end justify-between gap-2 mb-1">
                                                    <Label className="mb-0">
                                                        {
                                                            SECURITY_SETTINGS
                                                                .password_expires_after_days
                                                                .label
                                                        }
                                                    </Label>

                                                    <Toggle
                                                        checked={
                                                            typeof values
                                                                .settings
                                                                ?.password_expiration_enabled !==
                                                                'string' &&
                                                            values.settings
                                                                ?.password_expiration_enabled
                                                                ?.id === '1'
                                                        }
                                                        handleChange={(
                                                            checked
                                                        ) =>
                                                            setFieldValue(
                                                                'settings.password_expiration_enabled',
                                                                SECURITY_SETTINGS.password_expiration_enabled.options.find(
                                                                    (item) =>
                                                                        item.id ===
                                                                        (checked
                                                                            ? '1'
                                                                            : '0')
                                                                )
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <TextField
                                                    name="settings.password_expires_after_days"
                                                    placeholder="-"
                                                    disabled={
                                                        typeof values.settings
                                                            ?.password_expiration_enabled !==
                                                            'string' &&
                                                        values.settings
                                                            ?.password_expiration_enabled
                                                            ?.id !== '1'
                                                    }
                                                    trailingAddOn="dni"
                                                />
                                                {errors.settings
                                                    ?.password_expires_after_days && (
                                                    <span className="mt-1 text-sm text-red-600">
                                                        {
                                                            errors.settings
                                                                .password_expires_after_days
                                                        }
                                                    </span>
                                                )}
                                            </div>

                                            <div>
                                                <div className="flex items-end justify-between gap-2 mb-1">
                                                    <Label className="mb-0">
                                                        {
                                                            SECURITY_SETTINGS
                                                                .unique_passwords_in_row
                                                                .label
                                                        }
                                                    </Label>

                                                    <Toggle
                                                        checked={
                                                            typeof values
                                                                .settings
                                                                ?.unique_passwords_enabled !==
                                                                'string' &&
                                                            values.settings
                                                                ?.unique_passwords_enabled
                                                                ?.id === '1'
                                                        }
                                                        handleChange={(
                                                            checked
                                                        ) =>
                                                            setFieldValue(
                                                                'settings.unique_passwords_enabled',
                                                                SECURITY_SETTINGS.unique_passwords_enabled.options.find(
                                                                    (item) =>
                                                                        item.id ===
                                                                        (checked
                                                                            ? '1'
                                                                            : '0')
                                                                )
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <TextField
                                                    name="settings.unique_passwords_in_row"
                                                    placeholder="-"
                                                    disabled={
                                                        typeof values.settings
                                                            ?.unique_passwords_enabled !==
                                                            'string' &&
                                                        values.settings
                                                            ?.unique_passwords_enabled
                                                            ?.id !== '1'
                                                    }
                                                    trailingAddOn="razy"
                                                />
                                                {errors.settings
                                                    ?.unique_passwords_in_row && (
                                                    <span className="mt-1 text-sm text-red-600">
                                                        {
                                                            errors.settings
                                                                .unique_passwords_in_row
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </section>
                                    </div>
                                </div>

                                <div className="pt-8">
                                    <ActionBar
                                        rightSide={({
                                            isSubmitting,
                                            resetForm,
                                        }) => (
                                            <>
                                                <Button
                                                    onClick={() => resetForm()}
                                                    type="button"
                                                >
                                                    Anuluj
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                    className="ml-3"
                                                    loading={isSubmitting}
                                                    iconRight={<PlusIcon />}
                                                >
                                                    Zapisz
                                                </Button>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    )
}
