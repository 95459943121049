import React from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment/moment'

import {
    useCreateOperation,
    transformSurgeryDraftFormToApi,
    useGetExpenses,
} from 'api'
import {
    useNotification,
    useDoctorSurgeryDraftPolicyGroup,
    usePolicy,
} from 'hooks'
import {
    API_FORMAT_DATE,
    EXPENSES_TYPE_HOSPITAL_DAY_COST,
} from 'constants/index'
import { MESSAGES } from 'constants/index'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import DoctorSurgeryDraftForm from 'components/DoctorSurgeryDraftForm'

import type { FormSubmitFn, SurgeryDraftForm } from 'types'
import type { PostOperationDraft } from 'api/types'

const DoctorSurgeryDraftFormContainer: React.FC = () => {
    const navigate = useNavigate()
    const { user } = usePolicy()

    const surgeryDraftPolicyGroup = useDoctorSurgeryDraftPolicyGroup()

    const expenses = useGetExpenses(
        { type: EXPENSES_TYPE_HOSPITAL_DAY_COST },
        { enabled: surgeryDraftPolicyGroup.canCreate }
    )
    const { mutate: create } = useCreateOperation<Partial<PostOperationDraft>>()

    const showNotification = useNotification()

    const handleSubmit: FormSubmitFn<SurgeryDraftForm> = (
        values,
        formikHelpers
    ) => {
        create(
            transformSurgeryDraftFormToApi({
                status: 'draft',
                ...values,
            }),
            {
                onSuccess: () => {
                    navigate('/schedule')
                    showNotification({
                        content: MESSAGES.SURGERY_PROPOSAL_CREATED,
                        type: 'success',
                    })
                },
                onError: (error) => {
                    showNotification({
                        content: error.message,
                        type: 'danger',
                    })
                    formikHelpers.setErrors(error.errors)
                },
                onSettled: () => {
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    if (!surgeryDraftPolicyGroup.canCreate) {
        return (
            <AccessDenied message={MESSAGES.NO_PERMISSION_TO_CREATE_SURGERY} />
        )
    }

    if (expenses.isLoading) {
        return <Loader />
    }

    if (expenses.isError) {
        return <div>{expenses.error.message}</div>
    }

    return (
        <DoctorSurgeryDraftForm
            initialValues={
                {
                    draft: {
                        adult: true,
                        infected: true,
                        intensive_care: true,
                        additional_medication: true,
                        anesthesiologist_needed: true,
                        assistant_needed: true,
                        preparation_details: '',
                        other_notes: '',
                    },
                    estimated_date: moment().format(API_FORMAT_DATE),
                    preferred_beginning_hour: undefined,
                    estimated_duration: undefined,
                    surgical_team: user.isDoctor
                        ? [
                              {
                                  id: user.id,
                                  role: 'doctor',
                              },
                          ]
                        : [],
                    _doctor: user.isDoctor
                        ? {
                              id: user.id,
                              first_name: user.first_name,
                              last_name: user.last_name,
                              avatar_url: user.avatar_url,
                          }
                        : undefined,
                    recovery_days: '',
                    hospital_day_cost:
                        Number(expenses.data?.data[0]?.price) || 0,
                    payer: null,
                    equipment: [],
                    _equipment_item: undefined,
                    _equipment_item_phrase: '',
                    contains_implants: false,
                    implants: [],
                    _implant_item: undefined,
                    _implant_item_phrase: '',
                    _implant_set_item: undefined,
                    _implant_set_item_phrase: '',
                    _implant_price: '',
                    costs: [],
                    _costs_item: undefined,
                    _costs_item_phrase: '',
                    _expense_price: '',
                } as SurgeryDraftForm
            }
            handleSubmit={handleSubmit}
        />
    )
}

export default DoctorSurgeryDraftFormContainer
