import React from 'react'
import { Form, Formik } from 'formik'
import { Dialog } from '@headlessui/react'
import * as Yup from 'yup'
import { twMerge } from 'tailwind-merge'

import { Modal } from 'components'
import { Button } from 'components/ui'
import { TextField } from './forms'
import { SecurityRule } from '../api/types'

import type { IModal } from 'types'

import styles from 'components/Modal.module.scss'

const SecurityRuleModal: React.FC<{
    modal: IModal
    onClose: () => void
    onSubmit: (data: { index?: number; rule: SecurityRule }) => void
    onRemove: (index: number) => void
    rule: {
        index?: number
        rule: SecurityRule
    }
    existingRules: SecurityRule[]
}> = ({ modal, onClose, onRemove, onSubmit, rule, existingRules }) => {
    return (
        <Modal modal={modal} onClickOutside={onClose}>
            <div className={twMerge(styles.inner, 'p-6')}>
                <Dialog.Title as="h3" className={styles.title}>
                    Reguła logowania
                </Dialog.Title>

                <Formik
                    onSubmit={(values) => {
                        onSubmit({
                            index: rule.index,
                            rule: {
                                ...rule.rule,
                                label: values.label,
                                ip_address: values.ipAddress,
                            },
                        })
                    }}
                    initialValues={{
                        label: rule.rule.label,
                        ipAddress: rule.rule.ip_address || '',
                    }}
                    validateOnChange={false}
                    validationSchema={Yup.object({
                        label: Yup.string()
                            .max(
                                255,
                                'Nazwa nie może być dłuższa niż 255 znaków'
                            )
                            .required('Nazwa jest wymagana'),
                        ipAddress: Yup.string()
                            .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
                                message: 'Niepoprawny adres IP',
                                excludeEmptyString: true,
                            })
                            .test(
                                'ipAddress',
                                'Niepoprawny adres IP',
                                (value) => {
                                    if (
                                        value === undefined ||
                                        value.trim() === ''
                                    )
                                        return true
                                    return (
                                        value
                                            .split('.')
                                            .find((i) => parseInt(i) > 255) ===
                                        undefined
                                    )
                                }
                            )
                            .test(
                                'ipAddress',
                                'Podany adres IP istnieje już w regułach logowania',
                                (value) =>
                                    !existingRules
                                        .filter(
                                            (item) =>
                                                (item.ip_address || '') !==
                                                    (rule.rule.ip_address ||
                                                        '') ||
                                                typeof rule.index !== 'number'
                                        )
                                        .some(
                                            (item) =>
                                                (item.ip_address || '') ===
                                                (value || '')
                                        )
                            ),
                    })}
                >
                    {() => {
                        return (
                            <Form>
                                <div className="my-4">
                                    <TextField name="label" labelText="Nazwa" />
                                </div>

                                <div className="mb-6">
                                    <TextField
                                        name="ipAddress"
                                        labelText="IP"
                                    />
                                </div>

                                <div
                                    className={twMerge(
                                        styles.actionContainer,
                                        'flex items-center justify-end gap-3'
                                    )}
                                >
                                    {typeof rule.index === 'number' && (
                                        <Button
                                            tabIndex={-1}
                                            variant="danger"
                                            type="button"
                                            className="mr-auto"
                                            onClick={() =>
                                                typeof rule.index ===
                                                    'number' &&
                                                onRemove(rule.index)
                                            }
                                        >
                                            Usuń
                                        </Button>
                                    )}

                                    <Button
                                        tabIndex={-1}
                                        variant="default"
                                        type="button"
                                        onClick={() => onClose()}
                                    >
                                        Anuluj
                                    </Button>

                                    <Button
                                        tabIndex={-1}
                                        variant="primary"
                                        type="submit"
                                    >
                                        Zapisz
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </Modal>
    )
}

export default SecurityRuleModal
