import React, { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
    useCreateAccountType,
    useDeleteAccountType,
    useGetAccountType,
    useGetAccountTypeLimitations,
    useUpdateAccountType,
} from 'api'
import { SECURITY_SETTINGS } from '../constants'
import { useAccountTypePolicy, useNotification } from 'hooks'
import { AccessDenied } from 'components'
import { SettingsAccountTypeForm } from 'components/SettingsAccountTypeForm'

import {
    AccountTypeLimitation,
    ResponseError,
    SecuritySetting,
} from 'api/types'
import type { FormSubmitFn } from 'types'
import { AccountTypeFormType } from 'types/AccountTypeForm'

export const SettingsAccountTypeFormContainer: React.FC = () => {
    const { id } = useParams()
    const isEdit = !!id
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const accountTypePolicy = useAccountTypePolicy()
    const showNotification = useNotification()

    const accountTypesLimitations = useGetAccountTypeLimitations()
    const accountType = useGetAccountType(Number(id), {
        enabled: accountTypePolicy.canShow && isEdit,
    })
    const { mutate: createAccountType } = useCreateAccountType()
    const { mutate: updateAccountType } = useUpdateAccountType()
    const { mutate: deleteAccountType, isLoading: isDeletingAccountType } =
        useDeleteAccountType()

    const handleSubmit: FormSubmitFn<AccountTypeFormType> = (
        values,
        formikHelpers
    ) => {
        const options = {
            onSuccess: async () => {
                await queryClient.invalidateQueries('account-types')
                formikHelpers.setSubmitting(false)
                showNotification({
                    content: 'Zmiany zostały zapisane',
                    type: 'success',
                })

                navigate('/settings/security/account-types')
            },
            onError: (error: ResponseError) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
                showNotification({
                    content: 'Nie udało się zapisać zmian',
                    type: 'danger',
                })
            },
        }

        const isPasswordExpirationEnabled =
            typeof values.settings.password_expiration_enabled === 'object' &&
            values.settings.password_expiration_enabled.id === '1'
        const isUniquePasswordsEnabled =
            typeof values.settings.unique_passwords_enabled === 'object' &&
            values.settings.unique_passwords_enabled.id === '1'

        const data = {
            name: values.name,
            active: accountType.data?.data.active || false,
            settings: Object.entries(values.settings)
                .map(([key, value]) => ({
                    name: key as SecuritySetting['name'],
                    value:
                        typeof value === 'string' ? value : value.id.toString(),
                }))
                .filter((item) =>
                    item.name === 'unique_passwords_in_row'
                        ? isUniquePasswordsEnabled
                        : item.name === 'password_expires_after_days'
                        ? isPasswordExpirationEnabled
                        : true
                ),
            rules: values.rules,
        }

        isEdit
            ? updateAccountType({ ...data, id: Number(id) }, options)
            : createAccountType(data, options)
    }

    const handleDelete = () => {
        accountType.data &&
            deleteAccountType(
                { id: accountType.data.data.id },
                {
                    onSuccess: async () => {
                        showNotification({
                            content: 'Typ usunięty',
                            type: 'success',
                        })
                        navigate('/settings/security/account-types')
                    },
                }
            )
    }

    const dropdownOptions = useCallback(
        (name: AccountTypeLimitation['name']) => {
            const allOptions = SECURITY_SETTINGS[name].options
            const limitation = accountTypesLimitations.data?.data.find(
                (item) => item.name === name
            )

            return allOptions.filter((item) => {
                if (limitation?.sign === '>=') {
                    return Number(item.id) >= Number(limitation.value)
                }

                if (limitation?.sign === '<=') {
                    return Number(item.id) <= Number(limitation.value)
                }

                return true
            })
        },
        [accountTypesLimitations.data]
    )

    const initialFormData: AccountTypeFormType = useMemo(() => {
        const securityValue = (name: AccountTypeLimitation['name']) => {
            const availableOptions = dropdownOptions(name)
            const savedValue = availableOptions.find(
                (option) =>
                    option.id ===
                    accountType.data?.data.settings.find(
                        (setting) => setting.name === name
                    )?.value
            )
            const limitation = accountTypesLimitations.data?.data.find(
                (item) => item.name === name
            )

            return (
                savedValue ||
                availableOptions[
                    limitation?.sign === '<=' ? availableOptions.length - 1 : 0
                ]
            )
        }

        const passwordExpiresAfterDays = accountType.data?.data.settings.find(
            (item) => item.name === 'password_expires_after_days'
        )

        const uniquePasswordsInRow = accountType.data?.data.settings.find(
            (item) => item.name === 'unique_passwords_in_row'
        )

        return {
            name: accountType.data?.data.name || '',
            settings: {
                min_password_length: securityValue('min_password_length'),
                min_uppercase_letters: securityValue('min_uppercase_letters'),
                min_numbers: securityValue('min_numbers'),
                min_special_characters: securityValue('min_special_characters'),
                account_lockout_threshold: securityValue(
                    'account_lockout_threshold'
                ),
                password_expires_after_days:
                    passwordExpiresAfterDays?.value || '',
                unique_passwords_in_row: uniquePasswordsInRow?.value || '',
                password_expiration_enabled: SECURITY_SETTINGS[
                    'password_expiration_enabled'
                ].options.find(
                    (option) =>
                        option.id ===
                        (accountType.data?.data.settings.find(
                            (setting) =>
                                setting.name === 'password_expiration_enabled'
                        )?.value || '0')
                ),
                unique_passwords_enabled: SECURITY_SETTINGS[
                    'unique_passwords_enabled'
                ].options.find(
                    (option) =>
                        option.id ===
                        (accountType.data?.data.settings.find(
                            (setting) =>
                                setting.name === 'unique_passwords_enabled'
                        )?.value || '0')
                ),
            },
            rules: accountType.data?.data.rules || [],
        }
    }, [accountType.data, dropdownOptions, accountTypesLimitations.data])

    if (!accountTypePolicy.canCreate && !accountTypePolicy.canUpdate) {
        return <AccessDenied message="Nie masz uprawnień" />
    }

    if (accountType.isError) {
        return <div>{accountType.error.message}</div>
    }

    return (
        <SettingsAccountTypeForm
            initialFormData={initialFormData}
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
            isDeleting={isDeletingAccountType}
            isEdit={isEdit}
            getDropdownItems={dropdownOptions}
            accountTypesLimitations={accountTypesLimitations.data?.data}
        />
    )
}
